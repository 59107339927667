import Api from "@/services/Api";

export default {
  
  //Creates a deviceGroup
  //Takes in a name and a reference to the user group (groupId)
  createDeviceGroup(params) {
    return Api().post("/createDeviceGroup", params);
  },

  //Gets the device groups for the group
  //Takes in a reference for the current group (refGroup)
  getGroupDeviceGroups(params) {
    return Api().get("/getGroupDeviceGroups", params)
  },

  //Gets all devices belonging to a deviceGroup
  //Takes a reference (an integer) to the deviceGroup (refDeviceGroup)
  getDevicesFromGroup(params) {
    return Api().get("/getDevicesFromGroup", params)
  },

  //Adds a device to a deviceGroup
  //Takes a deviceId and deviceGroupId as parameters
  addDeviceToGroup(params) {
    return Api().post("/addDeviceToGroup", params)
  },

  //Removes a device from a given group
  //Takes in deviceId and deviceGroupId as parameters
  removeDeviceFromGroup(params) {
    return Api().post("/removeDeviceFromGroup", params)
  },

  //Deletes a certain deviceGroup
  //Takes deviceGroupId as a parameter
  deleteDeviceGroup(id) {
    return Api().delete(`/deleteDeviceGroup/${id}`);
    // return Api().delete('/entry/' + id);
  }
};
