<template>
  <!-- <v-chart class="chart" :option="option" @click="click"/> -->
  <v-chart class="chart" :option="option" />
</template>

<script>
import "echarts";
// import * as echarts from "echarts";
import VChart from "vue-echarts";

export default {
  components: {
    VChart,
  },
  props: {
    dataType: {
      type: String,
      required: false,
    },
    dataTypePt: {
      type: String,
      required: false,
    },
    dateList: {
      type: Array,
      required: false,
    },
    valueList: {
      type: Array,
      required: false,
    },
    measure: {
      type: String,
      required: false,
    },
  },
  watch: {
    dataType(val) {
      this.option.title.text = val;
    },
    dateList(val) {
      this.option.xAxis.data = val;
    },
    valueList(val) {
      this.option.series = val;
    },
    measure(val) {
      this.option.yAxis.name = "(" + val + ")      ";
    },
  },
  data() {
    return {
      option: {
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            saveAsImage: {},
          },
        },
        lineStyle: { color: "#3e77b6", width: 10, type: "dashed" },
        title: {
          left: "center",
          text: this.dataType,
          textStyle: {
            fontSize: 24,
            fontFamily: "roboto",
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          data: this.dateList,
        },
        yAxis: {
          name: "(" + this.measure + ")      ",
        },
        series: this.valueList,
      },
    };
  },
  methods: {
    // click(params) {
    //   console.log("teste", params);
      // this.valueList[params.seriesIndex].data.splice(params.dataIndex, 1);
      // this.valueList[params.seriesIndex].data[params.dataIndex] = undefined;
      // this.valueList.push("1");
      // this.valueList.pop();
    // }
  }
};
</script>

<style scoped>
.chart {
  height: 46vh;
}
</style>
