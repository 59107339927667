<template>
  <div class="container-card">
    <v-card style="height: 100%">
      <div class="mt-0 ma-0 pa-0" style="display: flex">
        <div class="mt-2 mt-0 ma-0 pa-0" style="margin: 6px">
          <img :src="img" style="width: 2.5vw; height: 2.5vw; float:left; margin-left: 4px" />
        </div>
        <div v-if="parameter == 'Temperature'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            <!--The '$t' specifies we are using the internationalization (translation) plugin 'i18n'-->
            {{ $t('dashboard.temperature') }}
          </span>
        </div>
        <div v-if="parameter == 'Carbon Dioxide'" class="mt-2 ml-0">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.carbonDioxide') }}
          </span>
        </div>
        <div v-if="parameter == 'Noise'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.noise') }}
          </span>
        </div>
        <div v-if="parameter == 'Humidity'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.humidity') }}
          </span>
        </div>
        <div v-if="parameter == 'Light Intensity'" class="mt-2 ml-0">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.lightIntensity') }}
          </span>
        </div>
        <div v-if="parameter == 'Air Quality'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.airQuality') }}
          </span>
        </div>
        <div v-if="parameter == 'Pressure'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.atmosphericPressure') }}
          </span>
        </div>
        <div v-if="parameter == 'Wifi Signal'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.wifiSignal') }}
          </span>
        </div>
        <div v-if="parameter == 'Light Colorimetry'" class="mt-2 ml-0">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.lightColorimetry') }}
          </span>
        </div>
        <div v-if="parameter == 'Light Temperature'" class="mt-2 ml-0">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.lightTemperature') }}
          </span>
        </div>
        <div v-if="parameter == 'Number of Devices'" class="mt-2 ml-4">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.numberOfDevices') }}
          </span>
        </div>
        <div v-if="parameter == 'Devices Flow'" class="mt-2 ml-0">
          <span style="font-weight: bold; font-size: 1.2vw">
            {{ $t('dashboard.devicesFlow') }}
          </span>
        </div>
      </div>
      

      <div>
        <v-card-text class="pa-0 ma-0" v-show="!(unit == 'r')">
          <div style="font-size: 1.4vw">{{ value }} {{ unit }}</div>
        </v-card-text>
        <v-card-text class="pa-0 ma-0" v-show="unit == 'r'">
          <div style="font-size: 1.2vw">
            <span style="color:red">{{ unit }}:{{ value }}</span> &nbsp;
            <span style="color:green">{{ unit1 }}:{{ value1 }}</span> &nbsp;
            <span style="color:blue">{{ unit2 }}:{{ value2 }}</span>
          </div>
        </v-card-text>
      </div>

      <div v-show="event.flag" style="display: flex" class="mt-0 ma-0 pa-0">
        <div v-show="event.severity == 0" class="ml-1 mt-2">
          <img :src="warning" style="width: 1.6vw; height: 1.6vw" />
        </div>
        <div v-show="event.severity == 1" class="ml-1 mt-2">
          <img :src="critical" style="width: 1.6vw; height: 1.6vw" />
        </div>
        <div v-show="event.severity == null" class="ml-1 mt-2">
          <img :src="ok" style="width: 1.6vw; height: 1.6vw" />
        </div>
        <div class="mt-2 ml-2">
          <span style="font-weight: bold; font-size: 0.8vw">
            {{ event.message }}
          </span>
        </div>
      </div>

      <div v-show="!event.flag" style="display: flex" class="mt-0 ma-0 pa-0">
        <div v-show="event.severity == null" class="mt-2 ml-1">
          <img :src="ok" style="width: 1.6vw; height: 1.6vw" />
        </div>
        <div class="">
          <!--Write below any message for green event-->
          <span style="font-weight: bold; font-size: 1vw"></span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    path: {
      required: true,
      type: String,
    },
    value: {
      required: true,
    },
    value1: {
      required: false,
    },
    value2: {
      required: false,
    },
    parameter: {
      required: true,
      type: String,
    },
    unit: {
      required: false,
      type: String,
      default: "",
    },
    unit1: {
      required: false,
      type: String,
      default: "",
    },
    unit2: {
      required: false,
      type: String,
      default: "",
    },
    event: {
      required: true,
      type: Object,
    },
  },
  computed: {
    img() {
      return "/assets/icons/" + this.path;
    },
    warning() {
      return "/assets/icons/alert.png";
    },
    critical() {
      return "/assets/icons/critical.png";
    },
    ok() {
      return "/assets/icons/success.png";
    },
  },
};
</script>

<style scoped>
.container-card {
  /* margin-left: 1%;
  margin-top: 1%; */
  width: 100%;
  height: 100%;
}
</style>