<template>
  <div>
    <v-chart class="chart" :option="option" @click="click" />
  </div>
</template>

<script>
import "echarts";
import * as echarts from "echarts";
import VChart from "vue-echarts";

export default {
  components: {
    VChart,
  },
  props: {
    dataType: {
      type: String,
    },
    devices: {
      type: Array,
    },
    date: {
      type: Array,
    },
    measure: {
      type: String,
      required: true,
    },
    rotation: {
      type: Number,
      required: true,
    },
    seriesData: {
      type: Array,
      required: true,
    }
  },
  watch: {
    dataType(val) {
      this.option.title.text = val;
    },
    devices(val) {
      this.option.xAxis.data = val;
    },
    seriesData(val) {
      this.option.series[0].data = val;
    },
    measure(val) {
      this.option.yAxis.name = '(' + val + ')      ';
    },
    rotation(val) {
      this.option.xAxis.axisLabel.rotate = val;
    },
  },
  data() {
    return {
      option: {
        title: {
          text: this.dataType,
          left: "center",
          textStyle: {
            fontSize: 24,
            fontFamily: "roboto",
          },
        },
        toolbox: {
          feature: {
            dataZoom: {
              yAxisIndex: "none",
            },
            dataView: { readOnly: false },
            magicType: { type: ["line", "bar"] },
            saveAsImage: {},
          },
        },
        tooltip: {
          trigger: "axis",
        },
        grid: {
          left: "5%",
          right: "5%",
          bottom: "5%",
          containLabel: true,
        },
        xAxis: {
          type: "category",
          data: this.devices,
          axisLabel: {
            interval: 0,
            rotate: this.rotation,
          }
        },
        yAxis: {
          name: '(' + this.measure + ')      ',
          type: "value",
          boundaryGap: "30%",
        },
        dataZoom: [
          {
            type: "inside",
          },
        ],
        series: [
          {
            type: 'bar',
            showBackground: true,
            data: this.seriesData,
            barWidth: "35%",
            
            emphasis: {
              itemStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  { offset: 0, color: '#2378f7' },
                  { offset: 0.7, color: '#2378f7' },
                  { offset: 1, color: '#83bff6' }
                ])
              }
            },
          }
        ]
      },
    };
  },

  methods: {
    click(params) {
      this.$emit('event', params)
    }
  }
};
</script>

<style scoped>
.chart {
  height: 46vh;
}
</style>
