import Api from "@/services/Api";

export default {
  // Creates a new entry on the "EventParameters" table. Takes an object (payload) with the info
  createNewEntry(payload) {
    return Api().post("createNewEntry", payload);
  },

  // Returns all event parameter entries for a given group
  getEntries(groupId) {
    return Api().get("getEntries", {
      params: {
        groupId,
      },
    });
  },

  // Returns all events generated for the last payload received on the database
  // Takes the id (dataId) of the last entry on the "Data" table and the device id
  getCurrentEvents(dataId, deviceId) {
    return Api().get("getCurrentEvents", {
      params: {
        dataId,
        deviceId,
      },
    });
  },

  // Returns all events issued for a given group
  getEvents(groupId, dateFrom, dateTo) {
    return Api().get("getEvents", {
      params: {
        groupId,
        dateFrom,
        dateTo
      },
    });
  },

  // Updates a given entry (providing the entry Id) on the "EventParameters" table to include an eventType and message
  // id, eventType and message are passed as a parameters object
  updateAction(parameters) {
    return Api().post("updateAction", parameters);
  },

  delete(id) {
    return Api().delete(`/entry/${id}`);
    // return Api().delete('/entry/' + id);
  },
};
