import Api from "@/services/Api";

export default {
  // Returns the last inserted data (array of objects) on the data table for a specific device.
  // Use it to get only the latest sensor values.
  // Takes an Integer, the device's id, as the argument.
  getCurrentData(device) {
    return Api().get("sensors", {
      params: {
        device,
      },
    });
  },

  // Returns the last inserted data (array of objects) on the data table for a set of devices.
  // Use it to get only the latest sensor values.
  // Takes an array of integers, the device's ids, as the argument.
  getAllDevicesCurrentData(devices) {
    return Api().get("sensorsData", {
      params: {
        devices,
      },
    });
  },

  // Returns data, an array of objects, from a specific device id, for a specific period of time.
  // Takes an integer (device Id) an initial date and a final date.
  getDefaultPlotData(deviceId, dateFrom, dateTo) {
    return Api().get("sensors/defaultData", {
      params: {
        deviceId,
        dateFrom,
        dateTo,
      },
    });
  },

  // Returns data, an array of objects, of all the last inserted rows for each device
  // on the data table. Takes an array of device Id's and a string for the dataType.
  getDataFromAllDevices(deviceIds, dataType) {
    return Api().get("sensors/dataFromAllDevices", {
      params: {
        deviceIds,
        dataType,
      },
    });
  },

  // Returns data, an array of objects, containing all the entries on the data table
  // for a set of devices (id's), an array of integers (selectedDevices) and for a
  // specific period of time (dateFrom + dateTo)
  getDataByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/dataByInterval", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  // Returns an array of data objects for a specific dataType and a determined period of time
  // Takes in an integer as device Id (selectedDevices), a period of time (dateFrom, dateTo) and a string type of data (dataOfType)
  getDataOfType(selectedDevices, dateFrom, dateTo, dataOfType) {
    switch (dataOfType) {
      // English
      case "Temperature":
        return this.getTempByInterval(selectedDevices, dateFrom, dateTo);
      case "Carbon Dioxide":
        return this.getCo2ByInterval(selectedDevices, dateFrom, dateTo);
      case "Noise":
        return this.getNoiseByInterval(selectedDevices, dateFrom, dateTo);
      case "Humidity":
        return this.getHumByInterval(selectedDevices, dateFrom, dateTo);
      case "Light Intensity":
        return this.getLuxByInterval(selectedDevices, dateFrom, dateTo);
      case "Air Quality":
        return this.getAirByInterval(selectedDevices, dateFrom, dateTo);
      case "Pressure":
        return this.getPressureByInterval(selectedDevices, dateFrom, dateTo);
      case "Wifi Signal":
        return this.getWifiByInterval(selectedDevices, dateFrom, dateTo);
      case "Light Temperature":
        return this.getLightTempByInterval(selectedDevices, dateFrom, dateTo);
      case "Number of Devices":
        return this.getDeviceCountByInterval(selectedDevices, dateFrom, dateTo);
      case "People Flow":
        return this.getDeviceFlowByInterval(selectedDevices, dateFrom, dateTo);
      // PORTUGUES
      case "Temperatura":
        return this.getTempByInterval(selectedDevices, dateFrom, dateTo);
      case "Dióxido de Carbono":
        return this.getCo2ByInterval(selectedDevices, dateFrom, dateTo);
      case "Ruído":
        return this.getNoiseByInterval(selectedDevices, dateFrom, dateTo);
      case "Humidade":
        return this.getHumByInterval(selectedDevices, dateFrom, dateTo);
      case "Intensidade da Luz":
        return this.getLuxByInterval(selectedDevices, dateFrom, dateTo);
      case "Qualidade do Ar":
        return this.getAirByInterval(selectedDevices, dateFrom, dateTo);
      case "Pressão":
        return this.getPressureByInterval(selectedDevices, dateFrom, dateTo);
      case "Sinal Wifi":
        return this.getWifiByInterval(selectedDevices, dateFrom, dateTo);
      case "Temperatura da Luz":
        return this.getLightTempByInterval(selectedDevices, dateFrom, dateTo);
      case "Número de Dispositivos":
        return this.getDeviceCountByInterval(selectedDevices, dateFrom, dateTo);
      case "Fluxo de Pessoas":
        return this.getDeviceFlowByInterval(selectedDevices, dateFrom, dateTo);
      default:
        return null;
    }
  },

  getPressureByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/pressure", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getDeviceCountByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/getDeviceCountByInterval", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getAirByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/air", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getWifiByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/wifi", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getTempByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/temperature", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getNoiseByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/noise", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getHumByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/humidity", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getCo2ByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/co2", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getLuxByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/ldr", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getLightTempByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/light_temp", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },

  getDeviceFlowByInterval(selectedDevices, dateFrom, dateTo) {
    return Api().get("sensors/deviceFlow", {
      params: {
        selectedDevices,
        dateFrom,
        dateTo,
      },
    });
  },
};
